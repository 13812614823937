import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Form from "../components/form"

import "../assets/sass/contact.scss"

const ContactPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <Seo title="Contato" />
        <Container className="contact padding-top padding-bottom">
          <Row className="title half-padding-bottom text-center justify-content-center">
            <Col md={10}>
              <h1>Entre em Contato</h1>
              <p>
                Preencha seus dados para economizar até 95% na conta de energia!
              </p>
              <p>
                Digite seus dados abaixo e nos diga o que procura, e entraremos
                em contato para realizar o seu orçamento.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              <Form />
            </Col>
          </Row>
          <Row className="justify-content-center half-padding-top">
            <Col md={10} className="branch">
              <Row>
                <Col>
                  <div>
                    <h5 className="title-sm">Matriz</h5>
                    <div className="sep" />
                  </div>
                  <p>Rua Senador Mendonça, 225 - Centro</p>
                  <p>Pelotas/RS</p>
                  <p>96015-200</p>
                </Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  )
}

export default ContactPage
